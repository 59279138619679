import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Loading from "./Components/loading.js";

const Home = lazy(() => import("./Pages/Home/Home"));
const Error404 = lazy(() => import("./Pages/Error404/Error404"));
const Oduduwa = lazy(() => import("./Pages/Oduduwa/Oduduwa"));
const BabaKing = lazy(() => import("./Pages/BabaKing/BabaKing"));

const Buzios = lazy(() => import("./Pages/Cursos/Buzios/Buzios"));
const BuziosObrigadoC = lazy(() =>
  import("./Pages/Cursos/Buzios/Checkout/Cartao.jsx")
);
const BuziosObrigadoB = lazy(() =>
  import("./Pages/Cursos/Buzios/Checkout/Boleto.jsx")
);

const Ori = lazy(() => import("./Pages/Cursos/Ori/Ori"));
const FichaOri = lazy(() =>
import("./Pages/Cursos/Ori/FichaTecnica/Ficha.jsx")
);
const OriObrigadoC = lazy(() =>
  import("./Pages/Cursos/Ori/Checkout/Cartao.jsx")
);
const OriObrigadoB = lazy(() =>
  import("./Pages/Cursos/Ori/Checkout/Boleto.jsx")
);

const Oxum = lazy(() => import("./Pages/Cursos/Oxum/Oxum"));
const OxumObrigadoC = lazy(() =>
  import("./Pages/Cursos/Oxum/Checkout/Cartao.jsx")
);
const OxumObrigadoB = lazy(() =>
  import("./Pages/Cursos/Oxum/Checkout/Boleto.jsx")
);

const Policy = lazy(() => import("./Pages/Policy/Policy"));

const WppBuzios = lazy(() => import("./Codes/index"));

const AbikuEgbe = lazy(() => import("./Pages/Cursos/AbikuEgbe/AbikuEgbe"));
const FichaAbiku = lazy(() =>
  import("./Pages/Cursos/AbikuEgbe/FichaTecnica/Ficha.jsx")
);
const AbikuEgbeObrigadoC = lazy(() =>
  import("./Pages/Cursos/AbikuEgbe/Checkout/Cartao.jsx")
);
const AbikuEgbeObrigadoB = lazy(() =>
  import("./Pages/Cursos/AbikuEgbe/Checkout/Boleto.jsx")
);

const Xango = lazy(() => import("./Pages/Cursos/Xango/Xango"));
const XangoObrigadoC = lazy(() =>
  import("./Pages/Cursos/Xango/Checkout/Cartao.jsx")
);
const XangoObrigadoB = lazy(() =>
  import("./Pages/Cursos/Xango/Checkout/Boleto.jsx")
);

const Axe = lazy(() => import("./Pages/Cursos/Axe/Axe"));
const AxeObrigadoC = lazy(() =>
  import("./Pages/Cursos/Axe/Checkout/Cartao.jsx")
);
const AxeObrigadoB = lazy(() =>
  import("./Pages/Cursos/Axe/Checkout/Boleto.jsx")
);

const Aje = lazy(() => import("./Pages/Cursos/Aje/Aje"));
const AjeObrigadoC = lazy(() =>
  import("./Pages/Cursos/Aje/Checkout/Cartao.jsx")
);
const AjeObrigadoB = lazy(() =>
  import("./Pages/Cursos/Aje/Checkout/Boleto.jsx")
);

const Ewe = lazy(() => import("./Pages/Cursos/Ewe/Ewe"));
const EweObrigadoC = lazy(() =>
  import("./Pages/Cursos/Ewe/Checkout/Cartao.jsx")
);
const EweObrigadoB = lazy(() =>
  import("./Pages/Cursos/Ewe/Checkout/Boleto.jsx")
);

const Olojo = lazy(() => import("./Pages/Cursos/Olojo/Olojo"));
const OlojoObrigadoC = lazy(() =>
  import("./Pages/Cursos/Olojo/Checkout/Cartao.jsx")
);
const OlojoObrigadoB = lazy(() =>
  import("./Pages/Cursos/Olojo/Checkout/Boleto.jsx")
);

const IyamiOxoronga = lazy(() =>
  import("./Pages/Cursos/IyamiOxoronga/IyamiOxoronga")
);
const IyamiOxorongaObrigadoC = lazy(() =>
  import("./Pages/Cursos/IyamiOxoronga/Checkout/Cartao.jsx")
);
const IyamiOxorongaObrigadoB = lazy(() =>
  import("./Pages/Cursos/IyamiOxoronga/Checkout/Boleto.jsx")
);

const Parabens = lazy(() => import("./Pages/Checkout/Parabens.jsx"));

export function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Cursos" element={<Home />} />
        <Route path="/BabaKing" element={<BabaKing />} />
        <Route path="/Oduduwa" element={<Oduduwa />} />
        <Route path="/*" element={<Error404 />} />

        <Route path="/Parabens" element={<Parabens />} />
        <Route path="/Parabéns" element={<Parabens />} />

        <Route path="/FormaçãoDeBúzios" element={<Buzios />} />
        <Route
          path="/FormaçãoDeBúzios/Obrigado-Cartao"
          element={<BuziosObrigadoC />}
        />
        <Route
          path="/FormaçãoDeBúzios/Obrigado-Boleto"
          element={<BuziosObrigadoB />}
        />
        <Route path="/FormacaodeBuzios" element={<Buzios />} />
        <Route
          path="/FormacaodeBuzios/Obrigado-Cartao"
          element={<BuziosObrigadoC />}
        />
        <Route
          path="/FormacaodeBuzios/Obrigado-Boleto"
          element={<BuziosObrigadoB />}
        />
        <Route path="/Formacao" element={<Buzios />} />
        <Route path="/Formacao/Obrigado-Cartao" element={<BuziosObrigadoC />} />
        <Route path="/Formacao/Obrigado-Boleto" element={<BuziosObrigadoB />} />
        <Route path="/Buzios" element={<Buzios />} />
        <Route path="/Buzios/Obrigado-Cartao" element={<BuziosObrigadoC />} />
        <Route path="/Buzios/Obrigado-Boleto" element={<BuziosObrigadoB />} />
        <Route path="/Búzios" element={<Buzios />} />
        <Route path="/Búzios/Obrigado-Cartao" element={<BuziosObrigadoC />} />
        <Route path="/Búzios/Obrigado-Boleto" element={<BuziosObrigadoB />} />

        <Route path="/Ori" element={<Ori />} />
        <Route path="/FichaTecnicaOri" element={<FichaOri />} />
        <Route path="/Ori/Obrigado-Cartao" element={<OriObrigadoC />} />
        <Route path="/Ori/Obrigado-Boleto" element={<OriObrigadoB />} />

        <Route path="/Oxum" element={<Oxum />} />
        <Route path="/Oxum/Obrigado-Cartao" element={<OxumObrigadoC />} />
        <Route path="/Oxum/Obrigado-Boleto" element={<OxumObrigadoB />} />

        <Route path="/Abiku" element={<AbikuEgbe />} />
        <Route path="/Egbe" element={<AbikuEgbe />} />
        <Route path="/AbikuEgbe" element={<AbikuEgbe />} />
        <Route path="/AbikueEgbe" element={<AbikuEgbe />} />
        <Route path="/FichaTecnicaAbikuEgbe" element={<FichaAbiku />} />

        <Route path="/Abiku/Obrigado-Cartao" element={<AbikuEgbeObrigadoC />} />
        <Route path="/Abiku/Obrigado-Boleto" element={<AbikuEgbeObrigadoB />} />
        <Route path="/Egbe/Obrigado-Cartao" element={<AbikuEgbeObrigadoC />} />
        <Route path="/Egbe/Obrigado-Boleto" element={<AbikuEgbeObrigadoB />} />
        <Route
          path="/AbikuEgbe/Obrigado-Cartao"
          element={<AbikuEgbeObrigadoC />}
        />
        <Route
          path="/AbikuEgbe/Obrigado-Boleto"
          element={<AbikuEgbeObrigadoB />}
        />
        <Route
          path="/AbikueEgbe/Obrigado-Cartao"
          element={<AbikuEgbeObrigadoC />}
        />
        <Route
          path="/AbikueEgbe/Obrigado-Boleto"
          element={<AbikuEgbeObrigadoB />}
        />

        <Route path="/Xango" element={<Xango />} />
        <Route path="/Xangô" element={<Xango />} />
        <Route path="/Xango/Obrigado-Cartao" element={<XangoObrigadoC />} />
        <Route path="/Xango/Obrigado-Boleto" element={<XangoObrigadoB />} />
        <Route path="/Xangô/Obrigado-Cartao" element={<XangoObrigadoC />} />
        <Route path="/Xangô/Obrigado-Boleto" element={<XangoObrigadoB />} />

        <Route path="/Axe" element={<Axe />} />
        <Route path="/Axé" element={<Axe />} />
        <Route path="/Axe/Obrigado-Cartao" element={<AxeObrigadoC />} />
        <Route path="/Axe/Obrigado-Boleto" element={<AxeObrigadoB />} />
        <Route path="/Axé/Obrigado-Cartao" element={<AxeObrigadoC />} />
        <Route path="/Axé/Obrigado-Boleto" element={<AxeObrigadoB />} />

        <Route path="/Aje" element={<Aje />} />
        <Route path="/Ajê" element={<Aje />} />
        <Route path="/Aje/Obrigado-Cartao" element={<AjeObrigadoC />} />
        <Route path="/Aje/Obrigado-Boleto" element={<AjeObrigadoB />} />
        <Route path="/Ajê/Obrigado-Cartao" element={<AjeObrigadoC />} />
        <Route path="/Ajê/Obrigado-Boleto" element={<AjeObrigadoB />} />

        <Route path="/Ewe" element={<Ewe />} />
        <Route path="/E.w.e" element={<Ewe />} />
        <Route path="/E.w.e/Obrigado-Cartao" element={<EweObrigadoC />} />
        <Route path="/E.w.e/Obrigado-Boleto" element={<EweObrigadoB />} />
        <Route path="/Ewe/Obrigado-Cartao" element={<EweObrigadoC />} />
        <Route path="/Ewe/Obrigado-Boleto" element={<EweObrigadoB />} />

        <Route path="/Olojo" element={<Olojo />} />
        <Route path="/Olojó" element={<Olojo />} />
        <Route path="/Olojo/Obrigado-Cartao" element={<OlojoObrigadoC />} />
        <Route path="/Olojo/Obrigado-Boleto" element={<OlojoObrigadoB />} />
        <Route path="/Olojó/Obrigado-Cartao" element={<OlojoObrigadoC />} />
        <Route path="/Olojó/Obrigado-Boleto" element={<OlojoObrigadoB />} />

        <Route path="/Oxoronga" element={<IyamiOxoronga />} />
        <Route path="/Iyami" element={<IyamiOxoronga />} />
        <Route path="/IyamiOxoronga" element={<IyamiOxoronga />} />
        <Route
          path="/Oxoronga/Obrigado-Cartao"
          element={<IyamiOxorongaObrigadoC />}
        />
        <Route
          path="/Oxoronga/Obrigado-Boleto"
          element={<IyamiOxorongaObrigadoB />}
        />
        <Route
          path="/Iyami/Obrigado-Cartao"
          element={<IyamiOxorongaObrigadoC />}
        />
        <Route
          path="/Iyami/Obrigado-Boleto"
          element={<IyamiOxorongaObrigadoB />}
        />
        <Route
          path="/IyamiOxoronga/Obrigado-Cartao"
          element={<IyamiOxorongaObrigadoC />}
        />
        <Route
          path="/IyamiOxoronga/Obrigado-Boleto"
          element={<IyamiOxorongaObrigadoB />}
        />

        <Route path="/Politica" element={<Policy />} />

        <Route path="/WppBuzios" element={<WppBuzios />} />
      </Routes>
    </Suspense>
  );
}
